import * as React from 'react';

const SvgIconFilter = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-icon="1" {...props}>
    <path d="M8.5 10.7188L3 10.7188" stroke="#0D1317" stroke-width="2" stroke-linejoin="round" />
    <path d="M10.5625 12.4375C11.5117 12.4375 12.2812 11.668 12.2812 10.7188C12.2812 9.76951 11.5117 9 10.5625 9C9.61326 9 8.84375 9.76951 8.84375 10.7188C8.84375 11.668 9.61326 12.4375 10.5625 12.4375Z" stroke="#0D1317" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12.625 5.21875L7.125 5.21875" stroke="#0D1317" stroke-width="2" stroke-linejoin="round" />
    <path d="M5.0625 6.9375C6.01174 6.9375 6.78125 6.16799 6.78125 5.21875C6.78125 4.26951 6.01174 3.5 5.0625 3.5C4.11326 3.5 3.34375 4.26951 3.34375 5.21875C3.34375 6.16799 4.11326 6.9375 5.0625 6.9375Z" stroke="#0D1317" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export default SvgIconFilter;