import React from 'react';

import { verifyIfLinkIsInAnotherDomain } from 'utils/url';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom Button
 *
 * Button
 */
function Button(props) {
  const {
    className = '',
    children,
    size = 'default',
    color = 'default',
    disabled = false,
    link = false,
    boldText = false,
    ...other
  } = props;

  const Tag = link ? 'a' : 'button';

  const isExternalLink = link ? verifyIfLinkIsInAnotherDomain(link) : false;

  return (
    <>
      <Tag
        className={`${css['atom__button-container']} ${className}`}
        data-size={size}
        data-color={color}
        data-bold={boldText}
        disabled={disabled ? true : undefined}
        href={link ? link : undefined}
        target={isExternalLink ? '_blank' : null}
        rel={isExternalLink ? 'noopener' : null}
        {...other}
      >
        {children}
      </Tag>
    </>
  );
}

export default Button;
